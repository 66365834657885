<template>
  <div>
    <div class="d-flex align-items-center p-3">
      <select class="form-control select-category mr-2">
        <option v-for="i in categories" :key="i">{{ i }}</option>
      </select>

      <button class="p-2 btn" v-b-modal.modal-category>
        <img src="./images/icon-filter.svg" />
      </button>

      <b-modal
        id="modal-category"
        title-class="modal-category-title"
        title="商品分類"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0 w-100 text-center">商品分類</h5>
          <b-button size="sm" variant="link" class="close" @click="close()">
            <img src="./images/icon-close.svg" />
          </b-button>
        </template>

        <div class="row filters">
          <div
            class="col-6 mb-2"
            v-for="i in categories"
            :key="i"
            :style="colorObject"
          >
            <button class="btn btn-light rounded-pill w-100 p-2 filter">
              {{ i }}
            </button>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div class="row w-100" :style="colorObject">
            <div class="col">
              <b-button
                size="lg"
                variant="outline-dark"
                class="w-100"
                @click="cancel()"
              >
                取消
              </b-button>
            </div>
            <div class="col">
              <b-button size="lg" variant="primary" class="w-100" @click="ok()">
                確認
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <button
        class="p-2 btn"
        @click="
          currentView =
            gridViews[(gridViews.indexOf(currentView) + 1) % gridViews.length]
        "
      >
        <img
          v-if="currentView == gridViews[0]"
          src="./images/icon-grid-2.svg"
        />
        <img
          v-if="currentView == gridViews[1]"
          src="./images/icon-grid-1.svg"
        />
        <img
          v-if="currentView == gridViews[2]"
          src="./images/icon-grid-list.svg"
        />
      </button>
    </div>
    <div class="products" :class="currentView">
      <div v-for="p in products" :key="p.id">
        <router-link
          class="products__item"
          role="button"
          :to="{ name: 'LiffShopProduct', params: { id: p.id } }"
        >
          <div class="products__item__photo product__photo">
            <img :src="getImage(p)" class="img-fluid" v-if="getImage(p)" />
          </div>

          <div class="products__item__title product__title">{{ p.title }}</div>
          <div class="products__item__brand">
            <div v-html="p.description"></div>
          </div>

          <div class="products__item__price">
            <div>
              <div class="oprice">{{ p.original_price }}</div>
              <div class="price">{{ p.price }}</div>
            </div>
            <button class="btn bag">
              <img src="./images/icon-shopping-bag.svg" />
            </button>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
import shopApi from "@/apis/liff/v2/shop";

const gridViews = ["grid-list", "grid-2", "grid-1"];

const categories = [
  "全部",
  "精品",
  "女性",
  "男性",
  "包包配件",
  "居家生活",
  "科技創意",
  "品味美食",
];

export default {
  mixins: [themeColor],
  data() {
    return {
      products: [],
      gridViews,
      categories,
      currentView: "grid-list",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      const data = await shopApi.getProducts();
      this.products = data.products.map((p) => ({
        ...p,
        description: p.description
          ? this.hiddenString(p.description.replace(/\n/g, "<br>"), 60)
          : null,
      }));
    },
    getImage(product) {
      return product.images[0]?.pic_url ?? false;
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
  },
};
</script>

<style lang="scss" scoped>
.select-category {
  border-radius: 0.5rem;
  border: 0;
  background-color: rgba(236, 239, 241, 0.5);
  font-family: "Noto Sans TC", "Helvetica";
  font-size: 14px;
  font-weight: 500;
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    border: solid 1px #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px;
    background-color: white;

    &__photo {
      grid-area: photo;
    }

    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    &__brand {
      grid-area: brand;
      font-size: 14px;
      font-weight: 400;
      color: #616161;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__price {
      grid-area: price;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .bag {
      padding: 0.8rem;
      border-radius: 0.5rem;
    }
  }

  &.grid-list {
    padding: 0 4px;

    @media screen and (min-width: 425px) {
      padding: 0 16px;
    }

    .products__item {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content max-content 1fr;
      gap: 0px 1rem;
      grid-template-areas:
        "photo title"
        "photo brand"
        "photo price";
      margin-bottom: 4px;

      @media screen and (min-width: 425px) {
        margin-bottom: 16px;
      }

      &__photo {
        height: calc(100% + 16px);
        margin: -8px 0 -8px -8px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.grid-1 {
    grid-template-columns: 1fr;
    display: grid;
    gap: 1rem;
    padding: 0 16px;

    .products__item {
      grid-template-columns: auto 30%;
      grid-template-rows: min-content min-content max-content;
      gap: 0px;
      grid-template-areas:
        "photo photo"
        "title price"
        "brand price";

      &__photo {
        margin: -10px;
        margin-bottom: 10px;
      }
      &__price {
        position: relative;
        align-items: start;
        justify-content: flex-end;
        font-size: 18px;
      }

      .bag {
        position: absolute;
        top: -5rem;
        right: 0.8rem;
        background-color: rgba(33, 33, 33, 0.5);
        img {
          filter: brightness(10);
        }
      }
    }
  }

  &.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    padding: 4px;

    @media screen and (min-width: 512px) {
      gap: 16px;
      padding: 16px;
    }

    .products__item {
      height: 100%;
      grid-template-columns: 100%;
      grid-template-rows: min-content min-content min-content auto;
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "brand"
        "price";

      &__photo {
        margin: -10px;
        margin-bottom: 10px;
      }
    }
  }
}

.filters {
  .filter {
    position: relative;
    background-color: #f5f7f8;
    border-color: #f5f7f8;
    font-size: 14px;
    font-weight: 500;
    &:focus,
    &:active,
    &.active {
      background-color: #90a4ae;
      border-color: #90a4ae;
      color: white;
      &::after {
        content: url("./images/icon-check.svg");
        position: absolute;
        right: 10px;
        fill: black;
      }
    }
  }
}
</style>
